import React, { useState, useCallback } from "react";
import { graphql } from "gatsby";
import cx from "classnames";

import Layout from "../components/Layout";
import Head from "../components/Head";
import Heading from "../components/Heading";
import InvoiceSystemLogo from "../images/invoiceSystem-white.svg";
import InvoiceSystem1 from "../images/Invoice-System-1.png";
import InvoiceSystem2 from "../images/Invoice-System-2.svg";
import InvoiceSystem3 from "../images/Invoice-System-3.svg";
import InvoiceSystem4 from "../images/Invoice-System-4.svg";
import InvoiceSystem5 from "../images/Invoice-System-5.svg";
import InvoiceSystem6 from "../images/Invoice-System-6.svg";
import HouseSelectedIcon from "../images/house-selected.svg";
import HouseIcon from "../images/house.svg";
import UsersIcon from "../images/users.svg";
import UsersSelectedIcon from "../images/users-selected.svg";

import styles from "./invoice.module.scss";

export const query = graphql`
  query($language: String!) {
    allMarkdownRemark(
      filter: {
        fields: {
          category: { eq: "text" }
          slug: { eq: "invoice" }
          language: { eq: $language }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
            language
          }
          frontmatter {
            title
            date
            header {
              title
              subtitle
              description
            }
            tabs {
              merchant
              user
            }
            sections {
              section {
                id
                title
                abovetitle
                subtitle
                description
              }
            }
          }
        }
      }
    }
  }
`;

const InvoicePageTemplate = ({ data }) => {
  const [value, setTabValue] = useState("merchant");

  const handleTabClick = useCallback((clickedTab) => () => {
    setTabValue(clickedTab);
  });

  const pageContent = data.allMarkdownRemark.edges[0].node.frontmatter;
  const { header, sections, tabs } = pageContent;
  const section1 = sections.filter((element) => element.section.id === "1")[0]
    .section;
  const section2 = sections.filter((element) => element.section.id === "2")[0]
    .section;
  const section3 = sections.filter((element) => element.section.id === "3")[0]
    .section;
  const section4 = sections.filter((element) => element.section.id === "4")[0]
    .section;
  const section5 = sections.filter((element) => element.section.id === "5")[0]
    .section;
  const section6 = sections.filter((element) => element.section.id === "6")[0]
    .section;

  const heading = (
    <Heading>
      <h1>
        <img className="icon" src={InvoiceSystemLogo} alt="" />
        {header.title}
      </h1>
      <h3>{header.subtitle}</h3>
      <h3>{header.description}</h3>
    </Heading>
  );

  return (
    <Layout heading={heading}>
      <Head title={pageContent.title} />
      <div className={styles.tabContainer}>
        <div
          className={value === "merchant" ? styles.tabSelected : styles.tab}
          onClick={handleTabClick("merchant")}
        >
          {value === "merchant" ? (
            <img src={HouseSelectedIcon} alt=""></img>
          ) : (
            <img src={HouseIcon} alt=""></img>
          )}
          <p>{tabs.merchant}</p>
        </div>

        <div
          className={value === "user" ? styles.tabSelected : styles.tab}
          onClick={handleTabClick("user")}
        >
          {value === "user" ? (
            <img src={UsersSelectedIcon} alt=""></img>
          ) : (
            <img src={UsersIcon} alt=""></img>
          )}
          <p>{tabs.user}</p>
        </div>
      </div>
      {value === "merchant" && (
        <div className={styles.mainContainer}>
          <div className={styles.sectionContainer}>
            <div className={styles.sectionPart}>
              <h6>{section1.abovetitle}</h6>
              <h1>{section1.title}</h1>
              <p>{section1.subtitle}</p>
              <p>{section1.description}</p>
            </div>
            <div className={styles.sectionPartImg}>
              <img src={InvoiceSystem1} alt={section1.title} />
            </div>
          </div>
          <div
            className={cx(styles.sectionContainer, styles.sectionWrapReverse)}
          >
            <div className={styles.sectionPartImg}>
              <img src={InvoiceSystem2} alt={section2.title} />
            </div>
            <div className={styles.sectionPart}>
              <h6>{section2.abovetitle}</h6>
              <h1>{section2.title}</h1>
              <p>{section2.subtitle}</p>
              <p>{section2.description}</p>
            </div>
          </div>
          <div className={styles.sectionContainer}>
            <div className={styles.sectionPart}>
              <h6>{section3.abovetitle}</h6>
              <h1>{section3.title}</h1>
              <p>{section3.subtitle}</p>
              <p>{section3.description}</p>
            </div>
            <div className={styles.sectionPartImg}>
              <img src={InvoiceSystem3} alt={section3.title} />
            </div>
          </div>
        </div>
      )}
      {value === "user" && (
        <div className={styles.mainContainer}>
          <div className={styles.sectionContainer}>
            <div className={styles.sectionPart}>
              <h6>{section4.abovetitle}</h6>
              <h1>{section4.title}</h1>
              <p>{section4.subtitle}</p>
              <p>{section4.description}</p>
            </div>
            <div className={styles.sectionPartImg}>
              <img src={InvoiceSystem4} alt={section4.title} />
            </div>
          </div>
          <div
            className={cx(styles.sectionContainer, styles.sectionWrapReverse)}
          >
            <div className={styles.sectionPartImg}>
              <img src={InvoiceSystem5} alt={section5.title} />
            </div>
            <div className={styles.sectionPart}>
              <h6>{section5.abovetitle}</h6>
              <h1>{section5.title}</h1>
              <p>{section5.subtitle}</p>
              <p>{section5.description}</p>
            </div>
          </div>
          <div className={styles.sectionContainer}>
            <div className={styles.sectionPart}>
              <h6>{section6.abovetitle}</h6>
              <h1>{section6.title}</h1>
              <p>{section6.subtitle}</p>
              <p>{section6.description}</p>
            </div>
            <div className={styles.sectionPartImg}>
              <img src={InvoiceSystem6} alt={section6.title} />
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default React.memo(InvoicePageTemplate);
